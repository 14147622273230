
import {computed, inject, onBeforeMount, onMounted, reactive, Ref, ref, watch} from "vue";
import {ENUMVoucherTypes} from "@/Interfaces/EnumVouchers";
import BasicSelectComponent from "@/components/elements/BasicSelectComponent.vue";

import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {Loader} from "@googlemaps/js-api-loader";
import {useSearchByName} from "@/composables/SearchByNameComposable"

export default {
  name: "TypeAheadAutocompleteComponent",
  components: {
    // tooltipBox,
    // AutoCompleteComponent,
    BasicSelectComponent,

  },
  emits: ["enter", "clear", "clearResults"],

  props: {
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  setup(
      props: {
        isDisabled: boolean;
      },
      {emit}: any
  ) {
    const router = useRouter();
    const route = useRoute();
    const {t} = useI18n({useScope: "global"});


    /**
     * Get a reference to the searching store object
     */
    const categoryStore: any = inject("categoryStore");
    const vouchersStore: any = inject("vouchersStore");
    const searchStore: any = inject("searchStore");
    const placeStore: any = inject("placeStore");
    const langStore: any = inject("langStore");

    /**
     * Setup the initial values of the models
     */
        // setup by the root the requested criteria
    const search_criteria: Ref<string> = ref("");

    // eslint-disable-next-line @typescript-eslint/ban-types
    const coords: Ref<object> = ref({});

    const nameAutoComplete = ref([])


    //setup by the root the requested search type
    const search_type: any = ref("nearby");

    const str = ref("");

    const setupAutocomplete = async () => {
      await loadGoogle();

      const google = await placeStore.getters.google;
      var options = {
        types: ['locality', 'postal_code'],
        componentRestrictions: {country: "be"},
        fields: ['geometry', 'formatted_address']
      };


      var the_element = document.getElementById("autocomplete-search-input");


      var autocomplete = new google.maps.places.Autocomplete(
          the_element,
          options
      );
      autocomplete.addListener('place_changed', function () {
        const place = autocomplete.getPlace();
        search_criteria.value = place.formatted_address;
        onEnter();
      })
      placeStore.setters.setGoogleAutocomplete(autocomplete)
    }

    const setupNearby = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              search_criteria.value = pos.lat + ',' + pos.lng;
              onEnter();
            },
            (error) => {
              let msg = '';

              switch (error.code) {
                case error.PERMISSION_DENIED:
                  msg = "User denied the request for Geolocation."
                  break;
                case error.POSITION_UNAVAILABLE:
                  msg = "Location information is unavailable."
                  break;
                case error.TIMEOUT:
                  msg = "The request to get user location timed out."
                  break;
                default:
                  msg = "An unknown error occurred."
                  break;
              }


              search_criteria.value = t('nearby.notfound')
              nearbyNotAllowed('notfound', msg)
            },
            {
              enableHighAccuracy: true,
              timeout: 7000,
              maximumAge: 0
            });
      } else {
        search_criteria.value = t('nearby.notsupported')
        nearbyNotAllowed('notsupported', "Nearby was not supported")
      }
      ;
    }

    const {fetchNames} = useSearchByName();


    const loadGoogle: any = async (): Promise<void> => {
      const loaderRef = new Loader({
        apiKey: process.env.VUE_APP_MAPS_API_KEY,
        libraries: ["places"],
        language: langStore.getters.getCurrentLang,
      });

      await loaderRef.load().then((google) => {
        placeStore.setters.setGoogle(google);
        placeStore.setters.setBoundsRef(new google.maps.LatLngBounds());
        return google;
      });
    }

    /**
     * Set the data when the component is mounted
     * @return void
     */
    onBeforeMount(() => {
      search_type.value = searchStore.getters.searchType;
      search_criteria.value = searchStore.getters.searchCriteria || undefined;

    });

    onMounted(() => {
      search_type.value = searchStore.getters.searchType;
      search_criteria.value = searchStore.getters.searchCriteria || undefined;
    });

    watch(() => search_type.value, async (n, o) => {
          emit('clearResults');
          search_criteria.value = '';
          if (search_type.value == 'location') {
            setupAutocomplete();
          } else if (search_type.value == 'nearby') {
            setupNearby();
          }

          await searchStore.setters.setSearchType(n);
        }
    )

    const setSearchType = async (newType: string): Promise<void> => {
      await searchStore.setters.setSearchType(newType);
    }

    function onInput(val: string) {
      str.value = val;
    };

    function nearbyNotAllowed(reason: string, errorCode: string) {
      console.log('There was an issue with the location determination. Error code: ' + errorCode)
      if (search_criteria.value.split(',').length !== 2) {
        if (reason == 'notsupported') {
          alert(t('nearby.notsupported.popup') + ' Error: ' + errorCode)
        } else if (reason == 'notfound') {
          alert(t('nearby.notfound.popup') + ' Error: ' + errorCode)
        }
        search_criteria.value = ''
        search_type.value = 'location'
        return


      }
    }

    async function selectName(val: string) {
      search_criteria.value = val;
      onEnter()
    }


    /**
     * What to be done on the keypres Enter
     */
    async function onEnter() {
      /* searchStore.setters.setSearchType(search_type.value);
      searchStore.setters.setSearchCriteria(search_criteria.value); */

      if (search_criteria.value.length > 0) {
        if (search_type.value == 'nearby') {
          if (search_criteria.value.split(',').length !== 2) {
            nearbyNotAllowed('notfound', 'Nearby was not found')
          }
        }
        await searchStore.setters.setSearchCriteria(search_criteria.value);

        if (route.name != "Search") {
          await router.push({
            name: "Search",
            params: {
              search_type: search_type.value,
              search_criteria: search_criteria.value,
            },
            query: {
              ...route.query,
            }
          });
        } else {
          await router.replace({
            params: {
              search_type: search_type.value,
              search_criteria: search_criteria.value,
            },
            query: {
              ...route.query,
            }
          });
        }

        emit("enter", {
          search_type: search_type.value,
          search_criteria: search_criteria.value,
        });
      } else {
        return;
      }
    }

    /**
     * Check if the input has enough data to be enabled for writing a criteria
     */
    const isInputDisabled = computed(() => {
      return props.isDisabled;
    });


    watch(() => search_criteria.value, async (n, o) => {
          if (search_type.value == 'name') {
            if (search_criteria.value.length > 2) {
              await fetchNames(search_criteria.value);
              nameAutoComplete.value = searchStore.getters.getAutocompleteNameArray

            } else {
              nameAutoComplete.value = []
            }
          }
        }
    )

    const updateAutocompleteNames = computed(() => {
      console.log('triggered autocomplete..')
      if (search_criteria.value === '') {
        return []
      }

      let matches = 0;

      return fetchNames(search_criteria.value);


      // return countries.filter(country => {
      //   if (
      //       country.name.toLowerCase().includes(searchTerm.value.toLowerCase())
      //       && matches < 10
      //   ) {
      //     matches++
      //     return country
      //   }
      // })
    });
    const onClear = function () {
      if (search_criteria.value.length) {
        placeStore.actions.clearFindings();
        searchStore.actions.clearSearch();
        search_criteria.value = '';

        router.push({
          name: "Home",
          query: {
            ...route.query,
          },
        });
      } else {
        placeStore.actions.clearFindings();
        return;
      }
    };

    return {
      vouchersStore,
      categoryStore,
      searchStore,
      search_criteria,
      coords,
      search_type,
      nameAutoComplete,
      isInputDisabled,
      updateAutocompleteNames,
      str,
      onEnter,
      onInput,
      onClear,
      setSearchType,
      selectName,
      options: ref([
        {
          value: "nearby",
          label: t('nearby'),
        },

        {
          value: "name",
          label: t('name'),
        },
        {
          value: "location",
          label: t('location'),
        },
      ]),
      ENUMVoucherTypes,
      t,
    };
  },
};
