import { ENUMVoucherTypes } from "@/Interfaces/EnumVouchers";
import { inject } from "vue";
import { AxiosResponse } from "axios";
import http from "@/services/http";
import { IPlace } from "@/Interfaces/PlacesInterfaces";
import {vouchersStore} from "@/stores/VoucherStore";

export function useSearchByName() {
  /**
   * Invoking the place store into the composable lib
   */
  const searchStore: any = inject("searchStore");
  const langStore: any = inject("langStore");

  /**
   * Extract all the categories from the given API
   *
   * @param lang
   * @param product
   */
  const
      fetchNames = async (
    query?: string
  ): Promise<AxiosResponse<IPlace[]>> => {
    return await http
      .get(`/${langStore.actions.getLangByValue(langStore.getters.getCurrentLang)}/api/acceptancepoint/autocomplete`, {
        params: {
          product: vouchersStore.actions.getSelectedLabel(),
          q: query,
        },
      })
      .then(async (value) => {
        searchStore.setters.setAutocompleteNameArray(value.data.slice(0,10));
        return value.data;
      });
  };

  return {
    fetchNames,
  };
}
