import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "hero is-red is-white" }
const _hoisted_2 = { class: "hero-body is-align-items-stretch p-0" }
const _hoisted_3 = {
  key: 1,
  class: "has-background-white has-text-centered has-text-danger p-2 mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavbar = _resolveComponent("MainNavbar")!
  const _component_HomeNavigationTabs = _resolveComponent("HomeNavigationTabs")!
  const _component_TypeAheadAutocompleteComponent = _resolveComponent("TypeAheadAutocompleteComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Overlay = _resolveComponent("Overlay")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MainNavbar, { ref: "NavBar" }, null, 512),
    _withDirectives(_createVNode(_component_HomeNavigationTabs, {
      key: 'nav-filters'+_ctx.$i18n.locale,
      ref: "NavTabs",
      "is-home": true,
      "voucher-type": _ctx.$route.query?.voucher_type,
      category: _ctx.$route.query?.category
    }, null, 8, ["voucher-type", "category"]), [
      [_vShow, _ctx.$route.name != 'MerchantDetails']
    ]),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["column is-12-tablet is-12-mobile is-absolute", {
          'has-background-white':  $setup.searchStore.getters.isStartedSearching || _ctx.$route.name == 'MerchantDetails',
          'is-5-desktop': _ctx.$route.name == 'Search'|| _ctx.$route.name == 'Home',
          'is-3-desktop': (_ctx.$route.name == 'MerchantDetails')
        }]),
          style: {zIndex: 9998, paddingBottom: 0}
        }, [
          (_ctx.$route.name == 'Home')
            ? (_openBlock(), _createBlock(_component_TypeAheadAutocompleteComponent, {
                key: _ctx.$i18n.locale,
                "is-disabled": !$setup.vouchersStore.getters.getSelected && !$setup.categoryStore.getters.getSelected,
                onEnter: $setup.onEnter
              }, null, 8, ["is-disabled", "onEnter"]))
            : _createCommentVNode("", true),
          ($setup.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("technical.issue")), 1))
            : (_openBlock(), _createBlock(_component_router_view, { key: 2 }))
        ], 2),
        _createElementVNode("div", {
          id: "map",
          ref: "myMapRef",
          class: _normalizeClass(["column is-12-tablet is-12-mobile p-0 is-pulled-right", [{
          'is-9-desktop': _ctx.$route.name == 'Search'|| _ctx.$route.name == 'MerchantDetails',
          'is-12-desktop': (_ctx.$route.name == 'Home') 
        }]]),
          style: _normalizeStyle({height: $setup.mapHeight})
        }, null, 6),
        ($setup.mapCoverClass != 'transparent')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["column is-9-tablet is-12-mobile p-0 is-pulled-right", [{
          'is-9-desktop': _ctx.$route.name == 'Search'|| _ctx.$route.name == 'MerchantDetails',
          'is-12-desktop': (_ctx.$route.name == 'Home') 
        }, $setup.mapCoverClass]]),
              style: _normalizeStyle({height: $setup.mapHeight, position: 'absolute'})
            }, null, 6))
          : _createCommentVNode("", true)
      ])
    ]),
    ($setup.isLoading)
      ? (_openBlock(), _createBlock(_component_Overlay, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}