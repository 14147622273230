
/* eslint-disable no-undef */
import {
  inject,
  onBeforeMount,
  onMounted,
  ref,
  Ref,
  watch, SetupContext,
} from "vue";
import {ENUMVoucherTypes} from "@/Interfaces/EnumVouchers";
import {onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

import {useScrollingSettings} from "@/composables/ScrollingComposable";
import {usePlaces} from "@/composables/PlacesComposable";
import {useMapsComposable} from "@/composables/MapsComposable";
import {useCategories} from "@/composables/CategoriesComposable";

import MainNavbar from "@/components/navigations/MainNavbar.vue";
import TypeAheadAutocompleteComponent from "@/components/elements/TypeAheadAutocompleteComponent.vue";
import HomeNavigationTabs from "@/components/tabs/HomeNavigationTabs.vue";
import Overlay from "@/components/elements/Overlay.vue";

import {Loader} from "@googlemaps/js-api-loader";
import {markerDisplay} from "@/reuseables/MarkerDisplay";

interface PropsHome {
  voucherType?: number;
  category?: string | undefined;
  lang: string;
}

export default {
  components: {
    MainNavbar,
    TypeAheadAutocompleteComponent,
    HomeNavigationTabs,
    Overlay,
  },
  name: "Home",
  emits: ["mouseover-search-result", "select"],
  props: {
    voucherType: {
      type: Number,
      required: false,
      default: undefined,
    },
    category: {
      type: String || undefined,
      required: false,
      default: undefined,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  async setup(props: PropsHome, context: SetupContext) {
    /**
     * Inject all the stores provided by the main app
     */
    const langStore: any = inject("langStore");
    const categoryStore: any = inject("categoryStore");
    const vouchersStore: any = inject("vouchersStore");
    const placeStore: any = inject("placeStore");
    const searchStore: any = inject("searchStore");
    const userStore: any = inject("userStore");
    const globalStore: any = inject("globalStore");
    /**
     * ------------------------------------------------------
     */
    const {t} = useI18n({useScope: "global"});
    const i18n = useI18n();




    /**
     * Center of Belgium
     */
    const lat: Ref<any> = ref(50.5010789);
    const lng: Ref<any> = ref(4.4764595);

    // language handlers
    // eslint-disable-next-line vue/no-setup-props-destructure
    const initLang = props.lang; // used for map language, which will correspond to the language on load.
    langStore.setters.setCurrentLang(props.lang)


    //# load the composable
    const {fetchPlaces, getItems, getFindings} = usePlaces();
    // const router = useRouter();
    const route = useRoute();
    // The main storage DIV Ref where the map would be placed in
    const myMapRef = ref();

    /**
     * Variable to store error data in case API calls fail
     */
    const error = ref(false);
    const isLoading: Ref<boolean> = ref(false);
    const mapHeight: Ref<string> = ref('100vh');
    const mapCoverClass: Ref<string> = ref('has-all-background');
    const listHeight: Ref<string> = ref('100%');
    const NavBar: any = ref(null);
    const NavTabs: any = ref(null);


    const {ecvStringToCatId, ecvCatIdToString} = useCategories();

    const {horizontal_settings, vertical_settings} = useScrollingSettings();
    //# map composable
    const {selectMarker, highlightMarker, prepareMarkers} = useMapsComposable();

    const loadGoogle: any = async (): Promise<void> => {
      const loaderRef = new Loader({
        apiKey: process.env.VUE_APP_MAPS_API_KEY,
        libraries: ["places"],
        language: initLang,
      });

      await loaderRef.load().then((google) => {
        placeStore.setters.setGoogle(google);
        placeStore.setters.setBoundsRef(new google.maps.LatLngBounds());
        return google;
      });
    }

    /**
     *
     * @param markers
     * @param clusterSize
     */
    const makeMap = async (markers?: Array<any>, voucher_type?: string) => {
      console.log("HOME: Start makeMap")

      await loadGoogle();

      const google = await placeStore.getters.google;


      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const map = new google.maps.Map(myMapRef.value, {
        // center: {lat: 50.8476, lng: 4.3572},
        mapId: process.env.VUE_APP_MAP_ID,
        center: placeStore.getters.myMapCenter,
        zoom: placeStore.getters.myMapZoom,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        disableDefaultUI: false,
      });
      google.maps.event.addListenerOnce(map, 'idle', function () {
        if (voucher_type === undefined) {
          const bounder = new google.maps.LatLngBounds()
          bounder.extend({lat: 50.5237, lng: 4.4579})
          map.fitBounds(bounder)
        }
        // we set zoom here because of bounds check later
        map.setZoom(globalStore.Zoom.value)
        if (route.name == "MerchantDetails") {
          if (markers) {
            const result = markers.find(element => {
              return element.id == route.params.merchant_id;
            });
            if (!map.getBounds().contains(result.position)) {
              map.setCenter(result.position)
            }
            const voucher_type: Ref<any> = ref(vouchersStore.getters.getSelected || route.query.voucher_type);
            if (voucher_type.value) {
              selectMarker(parseInt(route.params.merchant_id as string), vouchersStore.actions.findElement(parseInt(voucher_type.value))?.ico)
            }
          }
        }

      });
      console.log("HOME: Marker bounds")
      placeStore.actions.makeMap(map);
      await placeStore.actions.resetBounds();


      if (markers && voucher_type) {
        prepareMarkers(markers, map, placeStore.getters.getBoundsRef, voucher_type, undefined);
      }
      if (markers && markers.length || !map.getBounds()) {
        if (globalStore.Bounds.value === undefined) {
          map.fitBounds(placeStore.getters.getBoundsRef);
        } else {
          map.fitBounds(globalStore.Bounds.value);
        }
      }
      isLoading.value = false;
    };


    const render = async () => {
      console.log("HOME: RENDER HOME")
      const voucher_type: Ref<any> = ref(vouchersStore.getters.getSelected || route.query.voucher_type);
      const voucher_category: Ref<any> = ref(categoryStore.getters.getSelected || route.query.category || undefined);

      //console.log(voucher_category.value)
      // legacy - this param was used to set static image where the map should be
      mapCoverClass.value = 'transparent';

      i18n.locale.value = props.lang;
      if (voucher_type.value == 5 && voucher_category.value) {
        // voucher_category.value = ecvCatIdToString(voucher_category.value);
        // voucher_category.value = ecvCatIdToString(voucher_category.value);
        // this has been removed because now we also allow strings in the query as value.

      }
      document.title = t('pagetitle')


      isLoading.value = true;
      console.log("markers placestore")
      console.log(placeStore.getters.markers)
      console.log("========")
      if (route.name != "MerchantDetails" || !placeStore.getters.markers) {
        if (voucher_type.value) {
          if (voucher_type.value === 5) {
            lat.value = undefined;
            lng.value = undefined;
          }

          if (voucher_category.value) {
            //console.log("there is a specific category: " + voucher_category.value)
          } else {
            //console.log("there is no specific category.")
          }
          await fetchPlaces(
              voucher_category.value,
              vouchersStore.actions.findElement(parseInt(voucher_type.value))?.ico,
              undefined,
              undefined,
              undefined,
              undefined,
              'light'
          ).finally(async () => {
            error.value = false;

            if (getItems().length > 0) {
              await makeMap(getFindings() || getItems(), voucher_type.value);
            } else {
              error.value = true;
              isLoading.value = false;
            }
          }).catch((err) => {
            // Show generic error message
            console.log('ERROR ', err);
            error.value = true;
            isLoading.value = false;
          });
        } else {
          await makeMap();
        }
      } else {
        error.value = false;

        console.log("going to MerchantDetails make map");
        await makeMap(placeStore.getters.markers, voucher_type.value);
        selectMarker(parseFloat(route.params.merchant_id.toString()), vouchersStore.actions.findElement(parseInt(voucher_type.value))?.ico);
      }
    };

    /**
     * An Event handler which invokes a selection
     * Select the marker by provided a place record
     *
     * @param place
     */
    const onSearchHover = (place: Record<string, any>) => {
      // resetAllIcons();
      highlightMarker(place.id, vouchersStore.actions.findElement(parseInt(vouchersStore.getters.getSelected))?.ico);
    };

    /**
     * Cover map with background image with voucher type results
     *
     * @param voucherId - voucher type id
     */
    const showVoucherTypeMapCover = (voucherId: string): void => {
      mapCoverClass.value = 'has-' + voucherId + '-background';
    }

    /**
     * Before load the whole application
     */
    onBeforeMount(async () => {
      console.log("before mount")
      console.log(route.query)
      if (!vouchersStore.getters.getSelected && route.query.voucher_type) {
        await vouchersStore.setters.setSelected(route.query.voucher_type);
      }

      if (!categoryStore.getters.getSelected && route.query.category) {
        await categoryStore.setters.setSelected(route.query.category);
      }

      if (langStore.getters.getCurrentLang != props.lang) {
        await langStore.setters.setCurrentLang(props.lang);
      }
    });

    onMounted(async () => {
      console.log("on mounted")

      const NavHeight: number = NavTabs.value.$el.clientHeight + NavBar.value.$el.clientHeight + 1;

      mapHeight.value = 'calc(100vh - ' + NavHeight + 'px)';
      listHeight.value = 'calc(100vh - ' + (NavHeight + 72) + 'px)';

      if (!vouchersStore.getters.getSelected && route.query.voucher_type) {
        await vouchersStore.setters.setSelected(route.query.voucher_type)
      }

      if (!categoryStore.getters.getSelected && route.query.category) {
        await categoryStore.setters.setSelected(route.query.category);
      }

      await render();

    });

    /**
     * Extend search trigger functionality
     * @param searchModel
     */
    const onEnter = (searchModel: { search_type?: string, search_criteria: string }) => {
      isLoading.value = true;

      return;
    };

    watch(() => route.query, async (n, o) => {
      if (route.name == "Home") {
        await render();
      }
    });

    watch(() => userStore.getters.overlay, async (n, o) => {
      isLoading.value = n;
    });

    onBeforeRouteUpdate((to, from) => {
      const map = placeStore.getters.getMap;
      if (map !== undefined) {

        if (map.getZoom() !== undefined) {
          // this.config.globalProperties
          globalStore.Zoom.value = map.getZoom()
          globalStore.Center.value = map.getCenter()
          globalStore.Bounds.value = map.getBounds()
        }
      }
    });

    return {
      vouchersStore,
      categoryStore,
      searchStore,
      placeStore,
      langStore,
      userStore,
      horizontal_settings,
      vertical_settings,
      myMapRef,
      error,
      isLoading,
      onEnter,
      render,
      ENUMVoucherTypes,
      selectMarker,
      mapHeight,
      mapCoverClass,
      listHeight,
      NavTabs,
      NavBar,
      onSearchHover,
    };
  },
};
